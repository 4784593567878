<template>
  <q-dialog v-model="alert">
      <q-card>
        <q-card-section>
          <div class="text-h6">Alert</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <p v-for="m in messages" :key="m">{{ m }}</p>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="OK" color="primary" v-close-popup />
        </q-card-actions>
      </q-card>
 </q-dialog>
</template>
<script>
export default {
    props: {
        messages: String,
    },
    data () {
        return {
            alert: true
        }
    },
    watch: {
        messages: function() {
          this.alert = true;
        }
    }
}
</script>